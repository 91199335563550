import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyle from './src/theme/GlobalStyle'
import { ParallaxProvider } from 'react-scroll-parallax'

export const wrapRootElement = ({ element }) => {
  return(
    <>
    <GlobalStyle/>
    <ParallaxProvider>
      {element}
    </ParallaxProvider>
    </>
  )
}

export const wrapPageElement = ({element, props}) => {
  return (
    <>
    <CssBaseline/>
    {element}
    </>
  )
}