import { createGlobalStyle } from 'styled-components'

import { colors, fonts, sizes } from './style'
import { pxToRem, media } from './helpers'

const Global = createGlobalStyle`

  ::selection {
    background-color: ${colors.primary};
  }

  body {
    font-family: ${fonts.fontPrimary};
    color: ${colors.black};
    background: ${colors.white};
  }

  p {
    text-align: justify;
  }

  a {
    color: ${colors.black};
    text-decoration: none;
  }

  h1, .like-h1 {
    font-size: 36px;
    /* font-weight: normal; */
    font-family: ${fonts.fontTitle};
    border-bottom: solid 2px ${colors.accent};
    padding: 0 ${pxToRem(25)};
    ${media.large`
      font-size: ${fonts.h1FontSize};
    `};
  }

  h2, .like-h2 {
    text-align: center;
    font-size: 24px;
    /* font-weight: normal; */
    font-family: ${fonts.fontTitle};
    border-bottom: solid 2px ${colors.accent};
    padding: 0 ${pxToRem(25)};
    ${media.large`
      font-size: ${fonts.h2FontSize};
    `};
  }

  h3 {
    text-align: center;
    font-size: ${fonts.h3FontSize};
    /* font-weight: normal; */
    font-family: ${fonts.fontTitle};
    border-bottom: solid 2px ${colors.accent};
    padding: 0 ${pxToRem(25)};
  }

  h4 {
    text-align: justify;
    font-size: ${fonts.h4FontSize};
    /* font-weight: normal; */
    font-family: ${fonts.fontTitle};
    border-bottom: solid 2px ${colors.accent};
    padding: 0 ${pxToRem(25)};
    margin: auto;
  }

  .card {
    & h1, h2, h3, h4 {
      border: 0;
    }
  }

  .pageTitle {
    text-align: center;
    margin-top: ${pxToRem(32)};
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-title {
    margin-top: ${pxToRem(32)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-title h4 {
    text-align: justify;
    padding: 0;
    margin: ${pxToRem(16)} 0 ${pxToRem(8)} 0;
  }

  .bolder {
    font-weight: 900;
  }
  .bold {
    font-weight: 700;
  }
  .accent {
    color: ${colors.accent};
  }


  .pageTitle {
    margin-top: ${pxToRem(sizes.spaceTop)};
  }

  .notfound {
    margin-top: 20%;
    margin-bottom: 20%;
    text-align: center;
  }
`

export default Global
