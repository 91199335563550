import { css } from 'styled-components'
import { sizes } from './style'

export const pxToRem = (px = 16) => {
  return `${px / 16}rem`
}

export const layout = (px = 1080) => {
  return `
    max-width: ${pxToRem(px)};
    margin: 0 auto;
    padding: 0 ${pxToRem(10)};`
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}rem) {
      ${css(...args)}
    }
  `
  return acc
}, {})

// number to euro style
export const formatEuro = num => {
  if (!num) return
  return num
    .toFixed(2)
    .replace('.',',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €';
}