export const colors = {
  primary: '#005863',
  secondary: '#FBFBFB',
  accent: '#D0AC00',
  white: '#FFFFFF',
  black: '#3b3b3b',
  grey: '#3b3b3b',
  lightGrey: '#CCCCCC',
  lighterGrey: '#f4f4f4'
}

export const fonts = {
  fontPrimary: "myriad-pro, serif",
  fontHeroTitle: "'Caudex', cursive",
  fontTitle: "'Caudex', sans-serif",
  h1FontSize: '48px',
  h2FontSize: '36px',
  h3FontSize: '24px',
  h4FontSize: '16px',
}

export const anims = {
  type: "fade-in",
  delay: 300,
  easing: "ease-in-out",
}

export const sizes = {
  large: 1200,
  medium: 900,
  small: 600,
  spaceBlock: 60,
  spaceTop: 80
}

/* header nav */
export const styleNav = {
  height: 50, // hauteur en px (100)
  order: 1, // position logo (1 à gauche et 3 au milieu)
  spaceMenu: 50, // Espacement menu en px (50)
  breakPoint: sizes.medium, // breakpoint responsive
  darkMode: true,
}

export const images = {
  borderRadius: '8px',
}